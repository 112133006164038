@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

/* width */
::-webkit-scrollbar {
  width: auto;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e5e5e5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiDataGrid-panel {
  inset: -39px auto auto 6px !important;
}

.MuiDataGrid-panel .MuiDataGrid-filterForm {
  flex-direction: column;
  align-items: start;
}

.MuiDataGrid-panel .MuiDataGrid-filterForm .MuiFormControl-root {
  margin-bottom: 16px;
  width: 100%;
}

.MuiDataGrid-panel .MuiDataGrid-filterForm .MuiDataGrid-filterFormDeleteIcon {
  width: 30px;
}

.MuiDataGrid-panel .MuiDataGrid-filterForm .MuiDataGrid-filterFormValueInput {
  margin: 0px;
}